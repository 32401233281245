<template>
  <div v-loading="loaders.response" class="m-0 px-3 pt-4 pb-0 pr-2">
    <div class="d-flex justify-content-between">
      <h1 class="mt-4">Events List</h1>
      <div class="month-picker-container">
      <h4 class="form-label text-clip">Month / Year</h4>
      <vue-monthly-picker
        class="event-month-picker"
        @selected="getEvents"
        dateFormat="MMMM YYYY"
        :clearOption="false"
        placeHolder="Pick a month"
        :monthLabels="monthPickerLabels"
        selectedBackgroundColor="#832C44"
        v-model="request.eventDate">
      </vue-monthly-picker>
      </div>
    </div>
    <div class="mt-4 cardStyle">
      <el-table
        :data="response.events"
        row-key="date"
        class="table-responsive table-flush py-4"
        header-row-class-name="thead-light"
      >
        <el-table-column label="EVENT NAME">
          <template slot-scope="scope">
            <router-link :to="{ name: 'EventsRSVPReport', params: {eventId: scope.row.id, eventDate: getEncryptedEventDate(scope.row.date)}}">
              <span class="color-maroon">{{ scope.row.event_title }}</span>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column label="EVENT DATE" width="400">
          <template slot-scope="scope">
              <span>{{ scope.row.date | formatDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="ATTENDEES" width="350">
          <template slot-scope="scope">
              <span>{{ scope.row.patient_accepted_event }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {Option, Select, Table, TableColumn} from "element-ui";
import moment from "moment";
import {constants} from "@/util/constants";

export default {
  name: "EventsListForRSVP",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      loaders: {
        response: false,
      },
      request: {
        eventDate: new Date().toISOString()
      },
      response: {
        events: []
      },
      monthPickerLabels: constants.monthPickerLabels
    }
  },
  mounted() {
    this.getEvents()
  },
  filters: {
    /**
     * Return the formatted surgery date
     * @param date
     * @returns {string|string}
     */
    formatDate: function (date) {
      return date ? moment(date).format('MMM D, YYYY') : ''
    },
  },
  computed: {
    /**
     * Get date object
     * @returns {{month: number}}
     */
    getDateObject() {
      const date = new Date(this.request.eventDate)
      return {year: date.getFullYear(), month: date.getMonth() + 1}
    },
  },
  methods: {
    /**
     * Get Encrypted Date
     * @param date
     * @returns {string}
     */
    getEncryptedEventDate(date) {
      return btoa(date)
    },

    /**
     * Get Events
     */
    getEvents() {
      let vm = this
      vm.loaders.response = true
      vm.$store.dispatch('_getRequestedEvents', this.getDateObject)
        .then(response => {
          vm.response.events = response.data.data
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Events',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.response = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.form-label{
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0;
  color: #525F7F;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-month-picker {
  ::v-deep .date-popover {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
  }

  ::v-deep .month-picker-wrapper{
    min-width: 220px !important;
  }

  ::v-deep .month-year-display {
    height: calc(1.5em + 1.25rem - 1px);
    color: #606266;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
